
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'TheHeader',

  data() {
    return {
      userName: 'Светлана'
    }
  },

  methods: {
    userLogin() {
      console.log(this.userName)
    }
  }

})
