
import {defineComponent, PropType} from 'vue'
import {IStudent, ETableHeaders} from '@/model/types'

export default defineComponent({
  name: 'TheTable',

  props: {
    student: Object as PropType<IStudent>,
    is360: Boolean,
  },

  data() {
    return {
      tableHeaders: ETableHeaders
    }
  },

  methods: {
    getScoreColor(score: number): string {
      if (score < 3) return 'sheet-content--red'
      if (score >= 3 && score < 4) return 'sheet-content--orange'
      return 'sheet-content--green'
    },

    getPercentColor(isSvg: boolean, percent: number | undefined): string {
      if (!percent) return ''
      if (percent <= 25) return isSvg ? 'stroke-red' : 'progress-bar--red'
      if (percent > 25 && percent <= 50) return isSvg ? 'stroke-orange' : 'progress-bar--orange'
      return isSvg ? 'stroke-green' : 'progress-bar--green'
    },

    clickHandler(studentName: string = '') {
      console.log(`do something with the student: ${studentName}`)
    }
  }
})
