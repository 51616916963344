
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'AppSortButton',

  props: {
    direction: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false
    }
  }
})
