
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'AppSearch',

  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Ввести значение'
    },
    searchText: String
  },

  model: {
    prop: 'searchText',
    event: 'searchText'
  },

  methods: {
    resetSearch() {
      this.$emit('resetSearch')
    }
  }
})
