
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'AppColumnHeader',

  props: {
    title: {
      type: String,
      required: true
    },
    header: String,
    direction: String,
    criterion: String
  },

  computed: {
    classArrow(): string {
      if (this.header === this.criterion) {
        return this.direction === 'up' ? 'sheet-header--arrow-up': ''
      }
      return 'sheet-header--arrow-hidden'
    }
  }
})
