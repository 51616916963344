
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TheNotification",

  computed: {
    toastsArray() {
      return this.$store.getters.toastsArray
    }
  },

  watch: {
    toastsArray: function(newVal){
      if (newVal.length === 0) {
        this.$emit('remove-notif')
      }
    }
  }

})
